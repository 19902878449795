import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { Tag10 } from '../components/atoms/Tag/Tag10'
import { LayoutNews } from '../components/organisms/Layout/LayoutNews'
import { colorList } from '../components/styles/color'
import React from 'react'
import { SEO } from '../components/data/SEO'

interface QueryData {
  data: {
    microcmsNews: {
      content: string
      title: string
      newsId: string
      publishedAt: string
      thumbnail: {
        url: string
      }
      category: {
        name: string
      }
    }
  }
}

const newsStyle = css`
  color: ${colorList.text10};
  line-height: 1.7;
  font-size: 16px;
  h3 {
    color: ${colorList.text10};
    position: relative;
    font-size: 24px;
    line-height: 1.7;
    font-weight: bold;
    margin-top: 64px;
    margin-bottom: 40px;
    padding-top: 24px;
    &::before {
      content: '';
      width: 40px;
      height: 1px;
      background-color: ${colorList.primary};
      position: absolute;
      top: 0;
      left: 0;
    }
    @media screen and (max-width: 1199px) {
      font-size: 20px;
      padding-top: 20px;
    }
  }
  h4 {
    color: ${colorList.text10};
    margin-top: 16px;
    margin-bottom: 16px;
    line-height: 1.7;
    font-size: 16px;
    font-weight: bold;
  }
  a {
    color: blue;
  }
`

/**
 * ニュース個別ページ
 */
/** */
const News = ({ data }: QueryData) => {
  return (
    <LayoutNews title={data.microcmsNews.title}>
      <h2 className="font-bold pc:text-xxl text-lg leading-normal">
        {data.microcmsNews.title}
      </h2>
      <div>
        <div
          css={css`
            & > div {
              cursor: default;
            }
          `}
          className="flex mt-10 items-center"
        >
          <span className="pr-4 font-Rajdhani text-sm">
            {data.microcmsNews.newsId.slice(0, 4) +
              '/' +
              data.microcmsNews.newsId.slice(4, 6) +
              '/' +
              data.microcmsNews.newsId.slice(6, 8)}
          </span>
          <Tag10
            text={
              data.microcmsNews.category.name === 'info'
                ? 'お知らせ'
                : 'ニュースリリース'
            }
            isSelected
            size="s"
          />
        </div>
        <p className="text-xs mt-2 font-medium leading-normal">
          CRGホールディングス株式会社
        </p>
      </div>
      {data.microcmsNews.thumbnail?.url ? (
        <div className="relative mt-10 w-full mx-auto pt-[50%] pc:pt-0 pc:w-[768px] pc:h-[432px]">
          <img
            className={`absolute w-full top-0 left-0 h-full object-contain`}
            src={data.microcmsNews.thumbnail?.url}
            alt=""
          />
        </div>
      ) : (
        <div></div>
      )}

      <div
        css={newsStyle}
        className="mt-10"
        dangerouslySetInnerHTML={{
          __html: `${data.microcmsNews.content}`,
        }}
      />
    </LayoutNews>
  )
}

export default News

//newsIdはgatsby-node.jsのcontextで作成した変数
export const query = graphql`
  query MyNewsQuery($newsId: String!) {
    microcmsNews(newsId: { eq: $newsId }) {
      content
      title
      newsId
      publishedAt
      thumbnail {
        url
      }
      category {
        name
      }
    }
  }
`

/**
 * headタグの内容
 */
/** */
export const Head = ({ data }: QueryData) => (
  <SEO
    title={data.microcmsNews.title}
    url={`/news/${
      data.microcmsNews.category.name
    }/${data.microcmsNews.newsId.slice(0, 4)}/${data.microcmsNews.newsId.slice(
      4
    )}.html`}
  />
)
