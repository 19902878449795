import React, { ReactNode } from 'react'
import { MvChildPage } from '../Mv/MvChildPage'
import { layoutList } from '../../styles/layout'
import { LayoutRoot } from './LayoutRoot'

interface Props {
  children: ReactNode
  title: string
}

/**
 * News個別ページのレイアウト
 * @param {ReactNode} children
 * @param {string} title
 */
/** */
export const LayoutNews = ({ children, title }: Props) => {
  return (
    <LayoutRoot>
      <MvChildPage
        heading={'ニュース'}
        headingEnglish={'News'}
        BreadcrumbData={[
          { title: 'ニュース', url: '/news' },
          { title: title, url: '' },
        ]}
      />
      <div css={layoutList.layoutInner}>
        <div className="pc:w-[960px] mx-auto pc:px-12 pc:pt-12 pb-14 pc:mb-14 bg-white">
          <article className="overflow-x-auto">{children}</article>
        </div>
      </div>
    </LayoutRoot>
  )
}
