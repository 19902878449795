import React from 'react'

interface Props {
  text: string
  isSelected: boolean
  size: 's' | 'm'
}

/**
 * タグ。ニュースで使用
 * @param {string} text
 * @param {boolean} isSelected
 * @param {'s' | 'm'} size
 */
/** */
export const Tag10 = ({ text, isSelected, size = 'm' }: Props) => {
  return (
    <div
      className={`font-bold cursor-pointer inline-block rounded-full text-center border ${
        isSelected
          ? 'bg-primary border-primary text-white'
          : 'bg-white border-text20 text-text20'
      } ${size === 'm' ? 'py-2 px-4 text-sm' : 'py-1 px-3 text-xs'}`}
    >
      {text}
    </div>
  )
}
